import React, { Component } from 'react';
import PropTypes from 'prop-types';

import defaultColumns from '../../js/columns';
import { filterBuilds, sortBuilds } from '../../js/utils';

import TableHeader from './TableHeader';
import TableBody from './TableBody';

class Table extends Component {
  static getDerivedStateFromProps(props, state) {
    const { builds, search } = props;
    const { currentColumns, currentSortColumn, defaultSortColumn } = state;

    let newBuilds = sortBuilds(builds, currentSortColumn, defaultSortColumn);
    newBuilds = filterBuilds(builds, currentColumns, search);

    return {
      currentBuilds: newBuilds,
    };
  }

  constructor(props) {
    super(props);

    this.onSort = this.onSort.bind(this);

    this.state = {
      currentBuilds: [],
      currentColumns: props.columns,
      currentSortColumn: defaultColumns.date,
      defaultSortColumn: defaultColumns.date,
    };
  }

  onSort(sortColumn) {
    const { builds, search } = this.props;
    const { currentColumns, defaultSortColumn } = this.state;
    const newColumns = [];

    currentColumns.forEach((currentColumn) => {
      const newColumn = currentColumn;
      if (sortColumn === currentColumn) {
        newColumn.sortOrder = currentColumn.sortOrder === 'DESC' ? 'ASC' : 'DESC';
      } else {
        newColumn.sortOrder = 'DESC';
      }

      newColumns.push(newColumn);
    });

    let newBuilds = sortBuilds(builds, sortColumn, defaultSortColumn);
    newBuilds = filterBuilds(builds, currentColumns, search);

    this.setState({
      currentBuilds: newBuilds,
      currentColumns: newColumns,
      currentSortColumn: sortColumn,
    });
  }

  render() {
    const { loaded, hasStore, updateBanner } = this.props;
    const { currentBuilds, currentColumns } = this.state;

    const visibleColumns = currentColumns.filter(column => column.visible);

    return (
      <div className="table builds">
        <div className="table--header">
          <TableHeader
            columns={visibleColumns}
            onSort={this.onSort}
          />
        </div>

        <TableBody
          columns={visibleColumns}
          builds={currentBuilds}
          loaded={loaded}
          hasStore={hasStore}
          updateBanner={updateBanner}
        />
      </div>
    );
  }
}

export default Table;

Table.defaultProps = {
  columns: [
    defaultColumns.branch,
    defaultColumns.commit,
    defaultColumns.date,
    defaultColumns.published,
    defaultColumns.url,
  ],
  search: '',
};

Table.propTypes = {
  builds: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  hasStore: PropTypes.bool,
  updateBanner: PropTypes.func.isRequired,
};
