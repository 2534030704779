import React, { Component } from 'react';

import ThemeCard from '../components/ThemeCard';
import DashboardError from '../components/DashboardError';

export default class Themes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      builds: [],
    }
  }

  componentDidMount() {
    fetch(
      'api/2.0/themes',
      {
        credentials: 'same-origin',
      },
    )
      .then(response => response.json())
      .then(published => {
        this.setState({
          builds: published.sort((a, b) => a.name.localeCompare(b.name)),
        });
      })
      .catch(error => this.setState({ error: error.message }));
  }

  render() {
    const cards = [];

    if (this.state.error) {
      return <DashboardError error={this.state.error} />;
    }

    if (this.state.builds.length > 0) {
      this.state.builds.forEach(theme => {
        cards.push(<ThemeCard
          theme={theme.name}
          brand={theme.brand}
          version={theme.version}
          file={theme.file}
          rank={theme.rank}
          percentPositive={theme.percent_positive}
          key={theme.name}
        />);
      });
    } else {
      for (let i = 0; i < 9; i++) {
        cards.push(<ThemeCard placeholder key={i} />);
      }
    }

    return <div className="theme-cards">{ cards }</div>;
  }
}
