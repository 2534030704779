import React from 'react';
import SVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';

import {
  build,
  bug,
  insights,
  release,
  ranking,
} from '../../svg';

function renderLink(link, depth) {
  return (
    <li className={`navigation__item navigation__item--depth-${depth}`} key={link.key}>
      <NavLink
        href={`./${link.key}`}
        to={`./${link.key}`}
        key={link.key}
        className={`navigation__link navigation__link--depth-${depth}`}
        activeClassName="navigation__link--active"
      >
        {link.icon
          && (
            <SVG
              src={link.icon}
              className={`navigation__link-icon icon--${link.key}`}
            />
          )}

        <span className="navigation__link-label">
          { link.label }
        </span>
      </NavLink>

      { link.linklist && link.linklist.length > 0
        && renderMenu(link.linklist, depth + 1)}
    </li>
  );
}

function renderMenu(linklist, depth = 1) {
  return (
    <ul className={`navigation__menu navigation__menu--depth-${depth}`}>
      { linklist.map(link => renderLink(link, depth)) }
    </ul>
  );
}

export default function Navigation() {
  const linklist = [
    {
      key: 'builds',
      label: 'Builds',
      icon: build,
      linklist: [
        {
          key: 'performance',
          label: 'Performance',
          icon: insights,
        },
      ],
    },
    {
      key: 'releases',
      label: 'Releases',
      icon: release,
      linklist: [
        {
          key: 'insights',
          label: 'Insights',
          icon: insights,
        },
      ],
    },
    {
      key: 'theme-store',
      label: 'Theme Store',
      icon: ranking,
    },
  ];

  return (
    <div className="navigation-wrapper">
      <nav className="navigation">
        { renderMenu(linklist) }
      </nav>
    </div>
  );
}
