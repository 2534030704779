import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import { info } from '../../svg';

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.preventChildClick = this.preventChildClick.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  preventChildClick(event) {
    event.stopPropagation();
  }

  render() {
    const { html } = this.props;
    const iframe = document.createElement('iframe');

    if (this.iframe && this.iframe.contentWindow.document.body) {
      this.iframe.width  = this.iframe.contentWindow.document.body.scrollWidth;
      this.iframe.height = this.iframe.contentWindow.document.body.scrollHeight;
    }

    return (
      <div>
        <span
          className="file--info"
          onClick={this.openModal}
        >
          <SVG
            src={info}
            className="icon--download"
          />
        </span>

        <div
          className={
            this.state.open ?
            'modal modal--open' :
            'modal'
          }
          onClick={this.closeModal}
        >
          <div
            className="modal--window"
            onClick={this.preventChildClick}
          >
            <iframe title="performance" className="modal--iframe" srcDoc={html} ref={(f) => this.iframe = f} />
          </div>
        </div>
      </div>
    );
  }
}

export default Information;
