import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Theme from '../layout/Theme';
import Tab from '../layout/Tab';
import ThemeStoreChart from '../components/ThemeStoreChart';
import ChartFilters from '../components/ChartFilters';

export default class ThemeStore extends Component {
  constructor(props) {
    super(props);

    this.todaysDate = new Date(Date.now()).toISOString().split('T')[0];
    this.twoWeeksAgosDate = new Date(Date.now() - 12096e5).toISOString().split('T')[0];

    this.handleDateChange = this.handleDateChange.bind(this);

    this.state = {
      labels: [],
      ranking: [],
      percentPositive: [],
      startDate: this.twoWeeksAgosDate,
      endDate: this.todaysDate
    };
  }

  fetchChartData(theme) {
    const from = encodeURIComponent(this.state.startDate);
    const to = encodeURIComponent(this.state.endDate);
    
    fetch(
      `/api/2.0/themes/${theme}/theme-store/${from}/${to}`,
      {
        credentials: 'same-origin',
      },
    )
      .catch()
      .then(data => data.json())
      .then(themeStoreData => {
        // Reverse the data to plot the most recent date on the right of the chart
        const reversedData = themeStoreData.reverse();
        const rankingData = [];
        const reviewData = [];
        const labelData = [];
        reversedData.forEach(data => {
          const date = new Date(data.date);

          labelData.push(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
          rankingData.push(data.rank);
          reviewData.push(data.percent_positive_reviews);
        });

        this.setState({
          labels: labelData,
          ranking: rankingData,
          percentPositive: reviewData,
        });
      });
  }

  handleDateChange(property, value) {
    const { match } = this.props;
    const { theme } = match.params;
    this.setState({ [property]: value}, () => this.fetchChartData(theme));
  }

  componentDidMount() {
    const { match } = this.props;
    const { theme } = match.params;

    this.fetchChartData(theme);
  }

  render() {
    const { match } = this.props;
    const { theme } = match.params;
    const {
      labels,
      ranking,
      percentPositive,
      startDate,
      endDate
    } = this.state;

    return (
      <Theme theme={theme}>
        <Tab
          animate
          name="theme-store"
        >
          <ChartFilters
            startDate={startDate}
            endDate={endDate}
            todaysDate={this.todaysDate}
            handleDateChange={this.handleDateChange}
          />
          <ThemeStoreChart chartData={{ labels, ranking, percentPositive }} />
        </Tab>
      </Theme>
    );
  }
}

ThemeStore.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      theme: PropTypes.string,
    }),
  }).isRequired,
};
