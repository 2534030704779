import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import { chevron } from '../../svg';

function SortableHeader(props) {
  const { column, onSort } = props;

  return (
    <div
      key={column.key}
      className={`table--heading column--${column.key}`}
      onClick={() => onSort(column)}
      onKeyPress={() => onSort(column)}
      role="button"
      tabIndex="0"
      data-name={column.key}
    >
      <div className="table--heading-content">
        { column.visibleHeader && column.label }

        <div className="table--icons">
          <SVG
            src={chevron}
            className={column.sortOrder === 'ASC' ? 'icon--up' : 'icon--down'}
          />
        </div>
      </div>
    </div>
  );
}

function Header(props) {
  const { column } = props;

  return (
    <div
      key={column.key}
      className={`table--heading column--${column.key}`}
      data-name={column.key}
    >
      <div className="table--heading-content">
        { column.visibleHeader && column.label }
      </div>
    </div>
  );
}

export default class TableHeader extends Component {
  render() {
    const { columns, onSort } = this.props;

    return columns.map((column, index) => {
      if (column.sort) {
        return <SortableHeader column={column} onSort={onSort} key={index} />;
      }

      return <Header column={column} key={index} />;
    });
  }
}

SortableHeader.propTypes = {
  column: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

Header.propTypes = {
  column: PropTypes.object.isRequired,
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func.isRequired,
};
