import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';

import { childFactoryCreator } from '../js/utils';

import Login from './routes/Login';
import ThemeCards from './routes/ThemeCards';
import Header from './components/Header';
import Banner from './components/Banner';
import ThemeLatest from './routes/ThemeLatest';
import ThemeInsights from './routes/ThemeInsights';
import ThemeReleases from './routes/ThemeReleases';
import ThemePerformance from './routes/ThemePerformance';
import ThemeStore from './routes/ThemeStore';
import ThemeBugs from './routes/ThemeBugs';
import Profile from './routes/Profile';

const PrivateRoute = ({ component, authed, ...options }) => (
  authed ? <Route {...options} component={component} /> : <Redirect to={{ pathname: '/login' }} />
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      authed: false,
      banner: {
        visible: false,
        type: '',
        message: '',
      },
    };

    this.updateBanner = this.updateBanner.bind(this);
  }

  updateBanner(visible, type, message) {
    this.setState({
      banner: {
        visible,
        type,
        message,
      },
    });
  }

  componentDidMount() {
    fetch(
      '/api/2.0/auth/check',
      {
        credentials: 'same-origin',
      },
    )
      .catch(() => {
        this.setState({
          loading: false,
          authed: false,
        });
      })
      .then((data) => data.json())
      .then((response) => {
        this.setState({
          loading: false,
          authed: response.authenticated,
        });
      });
  }

  render() {
    const { loading, authed, banner } = this.state;

    if (loading) {
      return null;
    }

    const ContentWithRouter = withRouter(props => {
      const { location } = props;

      const path = location.pathname.split('/')[1];
      const transitionClass = path === '' ? 'slidein' : 'slideout';

      return (
        <div className="themes-dashboard">
          <Header authed={authed} />
          <Banner
            banner={banner}
            updateBanner={this.updateBanner}
          />
          <div className="themes-dashboard__content">
            <TransitionGroup
              childFactory={childFactoryCreator(transitionClass)}
            >
              <CSSTransition
                key={path}
                classNames={transitionClass}
                timeout={400}
              >
                <Switch key={location.key} location={location}>
                  <Route
                    exact
                    path="/login"
                    component={Login}
                  />

                  <PrivateRoute
                    exact
                    authed={authed}
                    path="/themes"
                    component={ThemeCards}
                  />

                  <PrivateRoute
                    exact
                    path="/profile"
                    authed={authed}
                    render={(props) => <Profile updateBanner={this.updateBanner} {...props} />}
                  />

                  <PrivateRoute
                    path="/themes/:theme/builds"
                    authed={authed}
                    render={(props) => <ThemeLatest updateBanner={this.updateBanner} {...props} />}
                  />

                  <PrivateRoute
                    path="/themes/:theme/performance"
                    authed={authed}
                    component={ThemePerformance}
                  />

                  <PrivateRoute
                    path="/themes/:theme/releases"
                    authed={authed}
                    render={(props) => <ThemeReleases updateBanner={this.updateBanner} {...props} />}
                  />

                  <PrivateRoute
                    path="/themes/:theme/insights"
                    authed={authed}
                    component={ThemeInsights}
                  />

                  <PrivateRoute
                    path="/themes/:theme/theme-store"
                    authed={authed}
                    component={ThemeStore}
                  />

                  <PrivateRoute
                    path="/themes/:theme/bugs"
                    authed={authed}
                    component={ThemeBugs}
                  />

                  <PrivateRoute
                    path="/"
                    authed={authed}
                    component={ThemeCards}
                  />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      );
    });

    return (
      <BrowserRouter>
        <ContentWithRouter />
      </BrowserRouter>
    );
  }
}

export default App;
