
import { formatDate } from './utils';
import { renderFile, renderReleased } from '../jsx/helpers/helpers';

export default {
  branch: {
    key: 'branch',
    dbKey: 'branch',
    label: 'Branch / Tag',
    filter: true,
    sort: true,
    sortOrder: 'DESC',
    visibleHeader: true,
    visible: true,
  },
  version: {
    key: 'version',
    dbKey: 'version',
    label: 'Version',
    filter: true,
    sort: true,
    sortOrder: 'DESC',
    visibleHeader: true,
    visible: true
  },
  tag: {
    key: 'tag',
    dbKey: 'tag',
    label: 'Tag',
    filter: true,
    sort: true,
    sortOrder: 'DESC',
    visibleHeader: true,
    visible: true,
  },
  date: {
    key: 'date',
    dbKey: 'built_at',
    label: 'Date',
    filter: true,
    sort: true,
    sortOrder: 'DESC',
    visibleHeader: true,
    visible: true,
    format: formatDate,
  },
  url: {
    key: 'url',
    dbKey: 'file',
    label: 'URL',
    filter: false,
    sort: false,
    sortOrder: 'DESC',
    visibleHeader: false,
    visible: true,
    render: renderFile,
  },
  commit: {
    key: 'commit',
    dbKey: 'hash',
    label: 'Commit',
    filter: true,
    sort: false,
    sortOrder: 'DESC',
    visibleHeader: true,
    visible: true,
  },
  published: {
    key: 'published',
    dbKey: 'published',
    label: 'Published',
    filter: false,
    sortOrder: 'DESC',
    visibleHeader: true,
    visible: true,
    render: renderReleased,
  },
};
