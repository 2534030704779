import React from 'react';

function childFactoryCreator(classNames) {
  return child => React.cloneElement(child, { classNames });
}

function formatDate(date) {
  const formattedDate = new Date(date);
  const locale = 'en-us';

  const day = formattedDate.getDate();
  const month = formattedDate.toLocaleString(locale, { month: 'long' });
  const year = formattedDate.getFullYear();

  const time = formattedDate.toLocaleString('en-US', {
    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true,
  });

  return `${month} ${day}, ${year} at ${time}`;
}

function sortBuilds(builds, column, defaultColumn) {
  const { dbKey, sortOrder } = column;
  const defaultDbKey = defaultColumn.dbKey;
  const defaultSortOrder = defaultColumn.sortOrder;

  builds.sort((buildA, buildB) => {
    const buildAValue = buildA[dbKey] || '';
    const buildBValue = buildB[dbKey] || '';
    const buildADefaultValue = buildA[defaultDbKey] || '';
    const buildBDefaultValue = buildB[defaultDbKey] || '';

    if (buildAValue < buildBValue) {
      return sortOrder === 'DESC' ? 1 : -1;
    } else if (buildAValue === buildBValue) {
      if (buildADefaultValue < buildBDefaultValue) {
        return defaultSortOrder === 'DESC' ? 1 : -1;
      } else if (buildADefaultValue === buildBDefaultValue) {
        return 0;
      }

      return defaultSortOrder === 'DESC' ? -1 : 1;
    }

    return sortOrder === 'DESC' ? -1 : 1;
  });

  return builds;
}

function filterBuilds(builds, columns, search) {
  const filteredBuilds = builds.filter((build) => {
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      let buildValue = build[column.dbKey];

      if (column.format) {
        buildValue = column.format(buildValue);
      }

      if (column.filter) {
        if (buildValue && buildValue.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
          return true;
        }
      }
    }

    return false;
  });

  return filteredBuilds;
}

export {
  childFactoryCreator,
  filterBuilds,
  formatDate,
  sortBuilds,
};
