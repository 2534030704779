import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Theme from '../layout/Theme';
import Tab from '../layout/Tab';
import Insights from '../components/Insights';

export default class ThemeInsights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      names: [],
      datasets: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { theme } = match.params;

    fetch(
      `/api/2.0/themes/${theme}/insights/1/15`,
      {
        credentials: 'same-origin',
      },
    )
      .catch()
      .then(data => data.json())
      .then(data => {
        const names = [];
        const datasets = {};

        data.reverse().forEach(benchmark => {
          names.push(benchmark.version);

          benchmark.statistics.forEach(statistic => {
            if (!(statistic.name in datasets)) {
              datasets[statistic.name] = [];
            }

            datasets[statistic.name].push(statistic.score * 100);
          });
        });

        this.setState({
          names,
          datasets,
        });
      });
  }

  render() {
    const { match } = this.props;
    const { theme } = match.params;
    const { names, datasets } = this.state;

    return (
      <Theme theme={theme}>
        <Tab
          animate
          name="insights"
        >
          <Insights insights={{ names, datasets }} />
        </Tab>
      </Theme>
    );
  }
}

ThemeInsights.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      theme: PropTypes.string,
    }),
  }).isRequired,
};
