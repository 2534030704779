import React from 'react';
import PropTypes from 'prop-types';

export default function Error(props) {
  return (
    <div className="dashboard-error">
      <div className="dashboard-error__message">
        <p>The themes dashboard encountered an unexpected error.</p>
        <p>{ props.error }</p>
      </div>
    </div>
  );
}

Error.propTypes = {
  error: PropTypes.string.isRequired,
};
