import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom'
import { back } from '../../svg';

export default function Hero(props) {
  const { theme } = props;

  return (
    <div className="hero">
      <Link
        href="/"
        to="/"
        className="hero--link"
      >
        <SVG
          src={back}
          className="hero--link-icon"
        />
      </Link>

      <h2 className="hero--heading">
        { theme }
      </h2>
    </div>
  );
}

Hero.propTypes = {
  theme: PropTypes.string.isRequired,
};
