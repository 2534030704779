import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

export default function Performance(props) {
  const { performance } = props;

  const chartColors = ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'];
  const datasets = Object.keys(performance.datasets).map((label, index) => ({
    label,
    data: performance.datasets[label],
    backgroundColor: chartColors[index % chartColors.length],
    borderColor: chartColors[index % chartColors.length],
    fill: false,
    spanGaps: true,
  }));

  return (
    <Line
      data={{
        labels: performance.names,
        datasets,
      }}
      options={{
        responsive: true,
        title: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      }}
      className="tab--chart"
    />
  );
}

Performance.propTypes = {
  performance: PropTypes.shape({
    names: PropTypes.arrayOf(PropTypes.oneOfType(
      [
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ],
    )),
    datasets: PropTypes.object,
  }).isRequired,
};
