import React from 'react';

export default function Login() {
  return (
    <div className="login">
      <h3 className="login__heading">
        Themes Dashboard
      </h3>

      <a href="/auth/google" className="login__link"><span className="login__screenreader">Sign in with Google</span></a>
    </div>
  );
}
