import React from 'react';
import PropTypes from 'prop-types';

import Pagination from '../components/Pagination';

export default function Tab(props) {
  const {
    name,
    animate,
    pagination,
    children,
  } = props;

  return (
    <div
      className={
        animate
          ? `tab tab--${name} tab--fade`
          : `tab tab--${name}`
      }
    >
      <div className="tab--content">{children}</div>

      { pagination && <Pagination page={pagination.page} count={pagination.count} />}
    </div>
  );
}

Tab.defaultProps = {
  animate: false,
  pagination: false,
};

Tab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  animate: PropTypes.bool,
  pagination: PropTypes.oneOfType([
    false,
    PropTypes.shape({
      page: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ]),
};
