import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { download, ranking, review } from '../../svg';

export default function ThemeCard(props) {
  const [releaseCounts, setReleaseCounts] = useState({});
  const {
    theme,
    brand,
    version,
    file,
    placeholder,
    rank,
    percentPositive,
  } = props;

  useEffect(() => {
    if (placeholder) return;
    fetch(
      `api/2.0/themes/${theme}/builds/counts`,
      {
        credentials: 'same-origin',
      },
    )
    .then(response => response.json())
    .then(builds => setReleaseCounts(builds));
  }, [placeholder, theme]);

  const { major, minor, patch } = releaseCounts;

  return (
    <div className={`theme-card ${placeholder && 'theme-card--placeholder'}`}>
      <div className="theme-card__header-wrapper">
        <div className="theme-card__header">
          <h2 className="theme-card__heading">{ theme }</h2>
          <span className="theme-card__subheading">{ brand }</span>
        </div>

        <div className="theme-card__releases">
          <h3 className="theme-card__releases-heading">Releases in the past 12 months</h3>
          <p className="theme-card__releases-release">Latest: { version }</p>
          <p className="theme-card__releases-release">Major: { major }</p>
          <p className="theme-card__releases-release">Minor: { minor }</p>
          <p className="theme-card__releases-release">Patches: { patch }</p>
        </div>
      </div>

      <div className="theme-card__footer">
        { rank
          && (
            <div className="theme-card__rank" data-tooltip="Shopify Theme Store rank">
              <SVG src={ranking} className="theme-card__rank-icon" />
              <span className="theme-card__rank-rank">{rank}</span>
            </div>
          ) }

        { percentPositive
          && (
            <div className="theme-card__reviews" data-tooltip="Percentage of positive reviews">
              <SVG src={review} className="theme-card__review-icon" />
              <span className="theme-card__reviews-percentage">{`${percentPositive}%`}</span>
            </div>
          ) }

        <a
          href={file ? `/assets/${file}` : '/'}
          className="theme-card__download"
          data-tooltip={`Download ${version}`}
          download={!!file}
        >
          <SVG
            src={download}
            className="theme-card__download-icon"
          />
        </a>
      </div>

      <Link
        to={!placeholder ? `/themes/${theme}/builds` : '/'}
        className="theme-card__link"
      />
    </div>
  );
}

ThemeCard.defaultProps = {
  theme: 'Placeholder',
  brand: 'Placeholder',
  version: 'Placeholder',
  file: '',
  placeholder: false,
  rank: 1,
  percentPositive: 100,
};

ThemeCard.propTypes = {
  theme: PropTypes.string,
  brand: PropTypes.string,
  version: PropTypes.string,
  file: PropTypes.string,
  placeholder: PropTypes.bool,
  rank: PropTypes.number,
  percentPositive: PropTypes.number,
};
