import React from 'react';

export default function Banner(props) {
  const {
    banner: {
      visible,
      type,
      message,
    },
    updateBanner,
  } = props;

  return (
    <div
      className={`
        banner__container
        banner__visible--${visible}
      `}
      onClick={() => updateBanner(false)}
    >
      <div
        className={`
          banner
          banner__${type}
          banner__${visible}
        `}
      >
        <p>
          {message}
        </p>
      </div>
    </div>

  );
}
