import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../js/utils';

import Theme from '../layout/Theme';
import Tab from '../layout/Tab';
import Table from '../components/Table';

export default class ThemeBugs extends Component {
  constructor(props) {
    super(props);

    this.filteredStories = null;
    this.bugStories = null;

    this.state = {
      bugs: [],
      count: 50,
    }
  }

  fetchData(theme) {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page') || 1, 10);
    const limit = 50;

    fetch(
      `/api/2.0/themes/${theme}/bugs`,
      {
        credentials: 'same-origin',
      }
    )
      .catch()
      .then(response => response.json())
      .then(stories => {
        if (!stories || 'error' in stories ) return;
        // Filter stories based on our criteria
        // Sort stories so that the ones with the most support tickets are at the top of the list
        this.filteredStories = stories.filter(story => {
          return story.story_type === 'bug' && !story.started && !story.completed && !story.archived;
        }).sort((a, b) => {
          return b.external_links.length - a.external_links.length;
        })

        // Shortens bug list based on the limit (ex. 50 per page)
        this.bugStories = this.filteredStories.slice(limit * (page - 1), limit * page);

        // Set state
        this.setState({
          bugs: this.bugStories,
          count: Math.ceil(this.filteredStories.length / limit),
        })

        // Store object in local storage representing bugs and expiry time
        // We are storing the longer list of bugs here
        // and then we can shorten it again based on the set page limit
        const d = new Date();
        const time = d.getTime();

        const bugsData = {
          storedStories: this.filteredStories,
          expiry: time + 300000,
        }

        localStorage.setItem(`${theme}-bugs`, JSON.stringify(bugsData));
      });
  }

  componentDidMount() {
    const { location, match } = this.props;
    const { theme } = match.params;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page') || 1, 10);
    const limit = 50;

    // Check local storage for stories
    let bugsData = JSON.parse(localStorage.getItem(`${theme}-bugs`));
    let storedStories;
    let expiry;

    if (bugsData) {
      ({ storedStories, expiry } = bugsData);

      // Compare expiry time with current time
      const now = new Date();
      if (now.getTime() > expiry) {
        localStorage.removeItem(`${theme}-bugs`);
        storedStories = null;

        // Fetch new data since our bugsData has expired
        this.fetchData(theme);
        return;
      } else {
        // Shortens bug list based on the limit (ex. 50 per page)
        this.bugStories = storedStories.slice(limit * (page - 1), limit * page);

        // Set state using what's stored
        // 'count' variable is used in pagination to know how many page links we'll have
        this.setState({
          bugs: this.bugStories,
          count: Math.ceil(storedStories.length / limit),
        })
      }
    }

    // If nothing in local storage, fetch data
    if (!storedStories) {
      // Make request to the server
      this.fetchData(theme);
    }
  }

  render() {
    const { location, match } = this.props;
    const { theme } = match.params;
    const { bugs, count } = this.state;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page') || 1, 10);
    const data = [
      {
        key: 'name',
        dbKey: 'name',
        label: 'Title',
        visibleHeader: true,
        visible: true,
        link: true,
      },
      {
        key: 'id',
        dbKey: 'id',
        label: 'Story ID',
        visibleHeader: true,
        visible: true,
      },
      {
        key: 'created_at',
        dbKey: 'created_at',
        label: 'Date',
        filter: true,
        sort: true,
        sortOrder: 'DESC',
        visibleHeader: true,
        visible: true,
        format: formatDate,
      },
      {
        key: 'external_links',
        dbKey: 'external_links',
        label: 'Tickets',
        visibleHeader: true,
        visible: true,
        count: true,
      },
    ];

    return (
      <Theme theme={theme}>
        <Tab
          animate
          name="bugs"
          pagination={{ page, count }}
        >
          <Table
            builds={bugs}
            loaded={!!bugs}
            columns={data}
            updateBanner={() => {}}
          />
        </Tab>
      </Theme>
    );
  }
}

ThemeBugs.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      theme: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
    }),
  }).isRequired,
};
