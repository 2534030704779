import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function Pagination(props) {
  const { count, page } = props;
  const paginationLinks = [];

  if (page !== 1) {
    paginationLinks.push(
      <Link
        to={`?page=${page - 1}`}
        key="previous"
        className="pagination--previous"
      >
        <span className="pagination--link-label">
          Prev
        </span>
      </Link>,
    );
  }

  if (page >= 4) {
    paginationLinks.push(
      <Link
        to={`?page=${1}`}
        key={1}
        className="pagination--link"
      >
        <span className="pagination--link-label">
          { 1 }
        </span>
      </Link>,
    );
  }

  if (page > 4) {
    paginationLinks.push(
      <div
        key="left_ellipsis"
        className="pagination--link"
      >
        <span className="pagination--link-label">...</span>
      </div>,
    );
  }

  for (let i = Math.max(Math.min(page - 2, count - 4), 1); i <= Math.min(Math.max(page + 2, 5), count); i++) {
    paginationLinks.push(
      <Link
        to={`?page=${i}`}
        key={i}
        className={
          page === i
            ? 'pagination--link pagination--link-active'
            : 'pagination--link'
        }
      >
        <span className="pagination--link-label">
          { i }
        </span>
      </Link>,
    );
  }

  if (page + 3 < count) {
    paginationLinks.push(
      <div
        key="right_ellipsis"
        className="pagination--link"
      >
        <span className="pagination--link-label">...</span>
      </div>,
    );
  }

  if (page + 3 <= count) {
    paginationLinks.push(
      <Link
        to={`?page=${count}`}
        key={count}
        className="pagination--link"
      >
        <span className="pagination--link-label">{ count }</span>
      </Link>,
    );
  }

  if (page !== count) {
    paginationLinks.push(
      <Link
        to={`?page=${page + 1}`}
        key="next"
        className="pagination--next"
      >
        <span className="pagination--link-label">
          Next
        </span>
      </Link>,
    );
  }

  return (
    <div className="pagination">
      { paginationLinks }
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};
