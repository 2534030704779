import React from 'react';
import PropTypes from 'prop-types';

import Hero from '../components/Hero';
import Navigation from '../components/Navigation';

export default function Theme(props) {
  const { theme, children } = props;

  return (
    <div className="theme">
      <div className="theme__hero"><Hero theme={theme} /></div>
      <div className="theme__nav"><Navigation /></div>

      <div className="theme__content">{children}</div>
    </div>
  );
}

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  theme: PropTypes.string.isRequired,
};
