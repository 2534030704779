import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ConfigureStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storeUrl: '',
      storePassword: '',
      hasStore: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);

    this.createStore = this.createStore.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event, updateBanner) {
    event.preventDefault();
    const { storeUrl, storePassword } = this.state;

    this.createStore(storeUrl, storePassword, updateBanner);
  }

  createStore(storeUrl, storePassword, updateBanner) {
    const method = this.state.hasStore ? 'PUT' : 'POST';
    fetch(
      '/api/2.0/users/stores',
      {
        method,
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url: storeUrl, password: storePassword })
      }
    )
      .catch()
      .then(data => data.json())
      .then(response => {
        const hasStore = response === 1;
        this.setState({
          hasStore,
        });

        hasStore
          ? updateBanner(true, 'success', 'Store was saved successfully!')
          : updateBanner(true, 'error', response)
      });
  }


  componentDidMount() {
    fetch(
      '/api/2.0/users/stores',
      {
        credentials: 'same-origin',
      }
    )
      .catch()
      .then(data => data.json())
      .then(response => {
        if (response.length > 0) {
          this.setState({
            storeUrl: response[0].store_url,
            storePassword: response[0].store_api_password,
            hasStore: true,
          });
        }
      });
  }

  render() {
    const { storeUrl, storePassword, hasStore } = this.state;
    const { updateBanner } = this.props;
    const buttonValue = hasStore ? 'Edit store' : 'Add store';

    return (
      <div className="store">
        <div className="store__description">
          <p>To enable instant click-to-deploy you will need to configure a private app in your development store. Follow the steps below to get the store name and password:</p>
          <ol>
            <li>In your development store, click on <strong>Apps</strong>.</li>
            <li>Click <strong>Develop apps</strong>.</li>
            <li>Allow <strong>enable custom app development</strong>.</li>
            <li>Select <strong>Create an app</strong>.</li>
            <li>Add a name to the <strong>App name</strong> field, then click <strong>Create app</strong>.</li>
            <li>Open <strong>Configure Admin API scopes</strong>.</li>
            <li>Scroll down to <strong>Themes</strong> to allow <strong>write_themes</strong> and <strong>read_themes</strong> access and then click <strong>Save</strong>.</li>
            <li>Open <strong>API credentials</strong> to click <strong>Install app</strong>, then Install.</li>
            <li>In the <strong>Admin API access token</strong> box, click <strong>Reveal token</strong> once to copy the token to your clipboard.</li>
            <li>Here in the dashboard, add your store handle to the <strong>Store name</strong> field below. Your store handle is the first portion of the URL: <strong>your-store</strong>.myshopify.com has a store name of <strong>your-store</strong>.</li>
            <li>Add the copied token to the <strong>Store password</strong> field.</li>
            <li>Click on <strong>Add store</strong> to save to the themes dashboard.</li>
          </ol>
          <p>You can now click on the Shopify icon beside the builds in the dashboard to automatically upload the build to your store!</p>
        </div>
        <form
          onSubmit={(e) => this.handleSubmit(e, updateBanner)}
          method="post"
          className="store__form"
        >
          <div className={`store__form-input-wrapper ${storeUrl.length > 0 && 'store__form-input-wrapper--filled'}`}>
            <input
              className="store__form-input"
              type="text"
              id="store-url"
              name="storeUrl"
              autoCapitalize="none"
              required
              value={storeUrl}
              onChange={this.handleInputChange}
            />
            <label
              className="store__form-label"
              htmlFor="store-url"
            >
              Store name
          </label>
          </div>

          <div className={`store__form-input-wrapper ${storePassword.length > 0 && 'store__form-input-wrapper--filled'}`}>
            <input
              className="store__form-input"
              type="password"
              id="store-password"
              name="storePassword"
              autoCapitalize="none"
              required
              value={storePassword}
              onChange={this.handleInputChange}
            />
            <label
              className="store__form-label"
              htmlFor="store-password"
            >
              Store password
          </label>
          </div>

          <div className="store__form-input-wrapper">
            <input
              className="store__form-submit"
              type="submit"
              value={buttonValue}
            />
          </div>
        </form>
      </div>
    );
  }
}

ConfigureStore.propTypes = {
  updateBanner: PropTypes.func.isRequired,
};
