import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { pxuLogo, ootsLogo, account } from '../../svg';

export default function Header(props) {
  const { authed } = props;

  return (
    <header className="header">
      <div className="header--link-group">
        <Link
          to="/"
          className="header--link"
        >
          <SVG
            src={ootsLogo}
            className="header--logo header--logo-oots"
          />
          <SVG
            src={pxuLogo}
            className="header--logo header--logo-pxu"
          />
        </Link>
      </div>
      { authed &&
        <div className="header--link-group">
          <Link
            to="/profile"
            className="header--link header--link-account"
          >
            <SVG
              src={account}
              className="header--logo"
            />
          </Link>
        </div>
      }
    </header>
  );
}

Header.propTypes = {
  authed: PropTypes.bool.isRequired,
};
