import React from 'react';
import PropTypes from 'prop-types';
import '@github/clipboard-copy-element';

export default function TableBody(props) {
  const { columns, builds, loaded, hasStore, updateBanner } = props;
  let content = null;

  if (!loaded) {
    content = (
      <div className="table--row placeholder">
        {
          columns.map((column, index) => (
            <span className={`table--cell column--${column.key}`} key={index}>
              Placeholder placeholder
            </span>
          ))
        }
      </div>
    );
  } else if (builds.length) {
    content = builds.map((build, index) => (
      <div className="table--row build" key={index}>
        {
          columns.map((column, index) => {
            let buildValue = build[column.dbKey];

            if (column.link) {
              buildValue = <a href={build.app_url}>{buildValue}</a>;
            }

            if (column.format) {
              buildValue = column.format(buildValue);
            }

            if (column.count) {
              buildValue = buildValue.length;
            }

            if (column.render) {
              buildValue = column.render(buildValue, build.published, build.html, hasStore, updateBanner);
            }

            return (
              <span className={`table--cell column--${column.key}`} key={index}>
                {buildValue}
              </span>
            );
          })
        }
      </div>
    ));
  } else {
    content = (
      <div className="table--no-results">
        <span className="table--no-results-spacer">No builds available</span>
        <span className="table--no-results-content">
          No builds available
        </span>
      </div>
    );
  }

  return (
    <div className="table--body">
      { content }
    </div>
  );
}

TableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  builds: PropTypes.arrayOf(PropTypes.object).isRequired,
  loaded: PropTypes.bool.isRequired,
  hasStore: PropTypes.bool,
  updateBanner: PropTypes.func.isRequired,
};
