import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';

import {
  shopify,
  build,
} from '../../svg';

class ClickToDeploy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deploying: false,
      deployed: false,
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(file, hasStore, updateBanner) {
    if (this.state.deployed) {
      return;
    }

    if (hasStore === false) {
      window.location = '/profile';
      return;
    }

    this.setState({
      deploying: true,
    });

    fetch(
      `/api/2.0/users/stores/deploy`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file,
        }),
      },
    )
      .catch((error) => {
        this.setState({
          deploying: false,
          deployed: false,
        });
        updateBanner(true, 'error', error);
      })
    .then(response => {
      this.setState({
        deploying: false,
        deployed: true,
      });

      const type = response.status === 201 ? 'success' : 'error';
      if (response.status === 201) {
        const message = `The build was successfully deployed to your store!`;
        updateBanner(true, type, message);
      } else {
        const message = `Deploying the build didn't work as expected. The following error was returned: ${response.statusText}`;
        updateBanner(true, type, message);
      }
    });
  }

  render() {
    const { file, hasStore, updateBanner } = this.props;
    const { deployed, deploying } = this.state;

    return (
      <div
        className={`file--deploy ${deployed && 'file--deployed'}`}
        onClick={() => this.handleClick(file, hasStore, updateBanner)}
      >
        { deploying ?
          <SVG
            src={build}
            className="icon--builds"
          />
          :
          <SVG
            src={shopify}
            className="icon--shopify"
          />
        }
      </div>
    )
  }
}

ClickToDeploy.propTypes = {
  file: PropTypes.string.isRequired,
  hasStore: PropTypes.bool.isRequired,
  updateBanner: PropTypes.func.isRequired,
}

export default ClickToDeploy;
