import React, { Component } from 'react';
import PropTypes from 'prop-types';

import defaultColumns from '../../js/columns';
import Theme from '../layout/Theme';
import Tab from '../layout/Tab';
import Table from '../components/Table';

export default class ThemeLatest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      builds: [],
      count: -1,
      hasStore: false,
    };
  }

  componentDidMount() {
    const { location, match } = this.props;
    const { theme } = match.params;
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const limit = 50;

    Promise.all([
      fetch(
        `/api/2.0/themes/${theme}/builds/latest/${page}/${limit}`,
        {
          credentials: 'same-origin',
        },
      )
        .catch()
        .then(data => data.json()),
      fetch(
        `/api/2.0/themes/${theme}/builds/latest`,
        {
          credentials: 'same-origin',
        },
      )
        .catch()
        .then(data => data.json())
        .then(builds => Math.ceil(Object.keys(builds).length / limit)),
      fetch(
        '/api/2.0/users/stores',
        {
          credentials: 'same-origin',
        }
      )
        .catch()
        .then(data => data.json())
        .then(response => response.length > 0),
    ]).then(([builds, count, hasStore]) => this.setState({
          builds,
          count,
          hasStore,
        }));
  }

  render() {
    const { location, match, updateBanner } = this.props;
    const { theme } = match.params;
    const { builds, count, hasStore } = this.state;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page') || 1, 10);

    return (
      <Theme theme={theme}>
        <Tab
          animate
          name="latest"
          pagination={{ page, count }}
        >
          <Table
            builds={builds}
            loaded={!!builds}
            columns={Table.defaultProps.columns.filter(column => column !== defaultColumns.published)}
            hasStore={hasStore}
            updateBanner={updateBanner}
          />
        </Tab>
      </Theme>
    );
  }
}

ThemeLatest.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      theme: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
    }),
  }).isRequired,
  updateBanner: PropTypes.func.isRequired,
};
