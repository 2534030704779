import React from 'react';
import SVG from 'react-inlinesvg';
import Information from '../components/Information';
import ClickToDeploy from '../components/ClickToDeploy';

import { download, contentCopy, released } from '../../svg';

function renderFile(file, published, html, hasStore, updateBanner) {
  const getUrl = window.location;
  const baseUrl = `${getUrl.protocol}//${getUrl.host}`;

  return (
    <div className="file">
      {
        html &&
        <Information html={html} />
      }

      <ClickToDeploy
        file={file}
        hasStore={hasStore}
        updateBanner={updateBanner}
      />

      <clipboard-copy class="file--copy" value={`${baseUrl}/assets/${file}`}>
        <SVG
          src={contentCopy}
          className="icon--download"
        />
      </clipboard-copy>

      <a className="file--download" href={`/assets/${file}`}>
        <SVG
          src={download}
          className="icon--download"
        />
      </a>
    </div>
  );
}

function renderReleased(published) {
  return published ? (
    <div className="released">
      <SVG
        src={released}
        className="icon--released"
      />
    </div>
  ) : null;
}

export {
  renderFile,
  renderReleased
};
