import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

export default function ThemeStoreChart(props) {
  const { chartData } = props;

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: "Ranking",
        data: chartData.ranking,
        fill: false,
        yAxisID: 'ranking',
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Percentage of Positive Reviews",
        data: chartData.percentPositive,
        fill: false,
        yAxisID: 'percentage',
        borderColor: "#742774"
      }
    ]
  };

  const options = {
    legend: {
      display: true,
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
        top: 25,
        bottom: 0,
      },
    },
    responsive: true,
    tooltips: {
      mode: 'label'
    },
    title: {
      display: true,
      text: 'Shopify theme store sales & review performance',
      fontSize: 16,
    },
    scales: {
      yAxes: [{
        type: 'linear',
        display: true,
        position: 'left',
        id: 'ranking',
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: 'Ranking',
          fontSize: 16,
        },
        ticks: {
          min: 1,
          max: 100,
          reverse: true,
          callback: (value) => `#${value}`,
        },
      }, {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'percentage',
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: 'Percentage of Positive Reviews',
          fontSize: 16,
        },
        ticks: {
          max: 100,
          beginAtZero: true,
          callback: (value) => `${value}%`,
        },
      }],
    },
  }

  const plugins = [{
    afterDraw: (chartInstance) => {
      const ctx = chartInstance.chart.ctx;
      const canvasHeight = chartInstance.canvas.height;
      const canvasWidth = chartInstance.canvas.width;
      const startingX = 0.15 * canvasWidth;
      const startingY = 0.75 * canvasHeight;
      ctx.fillRect(startingX, startingY, 400, 75);
      ctx.fillStyle = '#808080';
      ctx.fillText("Theme popularity is based on a 90-day rolling", startingX + 10, startingY + 20);
      ctx.fillText("average of sales and does not include Shopify's", startingX + 10, startingY + 40);
      ctx.fillText("free themes.", startingX + 10, startingY + 60);
    },
  }];

  return (
    <Line
      data={data}
      options={options}
      plugins={plugins}
      className="tab--chart"
    />
  )
}

ThemeStoreChart.propTypes = {
  chartData: PropTypes.object.isRequired,
}
