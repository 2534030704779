import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConfigureStore from '../components/ConfigureStore';

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    }
  }

  componentDidMount() {
    fetch(
      'api/2.0/users',
      {
        credentials: 'same-origin',
      },
    )
      .catch((error) => {
        console.log(error);
      })
      .then((data) => data.json())
      .then((response) => {
        this.setState({
          user: response,
        });
      });
  }

  render() {
    const { updateBanner } = this.props;
    const { user } = this.state
    return (
      <div className="profile">
        <h2 className="profile__heading">
          { user.username }
        </h2>
        <ConfigureStore
          user={user}
          updateBanner={updateBanner}
        />
      </div>
    )
  };
}

Profile.propTypes = {
  updateBanner: PropTypes.func.isRequired,
};
