import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../components/DatePicker';

export default function ChartFilters(props) {
  const {
    startDate,
    endDate,
    todaysDate,
    handleDateChange,
  } = props;

  return (
    <section className="date-filter__wrapper">
      <div className="date-filter__item">
        <DatePicker
          value={startDate}
          min="2020-09-01"
          max={todaysDate}
          onChange={e => handleDateChange('startDate', e.detail.value)}
        />
      </div>
      <div className="date-filter__item">
        <DatePicker
          value={endDate}
          min={startDate}
          max={todaysDate}
          direction="left"
          onChange={e => handleDateChange('endDate', e.detail.value)}
        />
      </div>
    </section>
  );
}

ChartFilters.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  todaysDate: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};
