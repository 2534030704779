import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Theme from '../layout/Theme';
import Tab from '../layout/Tab';
import Performance from '../components/Performance';

export default class ThemePerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      names: [],
      datasets: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { theme } = match.params;

    fetch(
      `/api/2.0/themes/${theme}/performance/1/450`,
      {
        credentials: 'same-origin',
      },
    )
      .catch()
      .then(data => data.json())
      .then(data => {
        const names = [];
        const datasets = {
          Performance: [],
          'First Contentful Paint': [],
          'First Meaningful Paint': [],
          'Speed Index': [],
          'First CPU Idle': [],
          'Time to Interactive': [],
        };

        const groupedBenchmarks = {};
        let averagedBenchmarks = [];

        data.forEach(benchmark => {
          const uniqueId = `${benchmark.hash}${benchmark.built_at}`;

          if (!(uniqueId in groupedBenchmarks)) {
            groupedBenchmarks[uniqueId] = [];
          }

          groupedBenchmarks[uniqueId].push(benchmark);
        });

        Object.keys(groupedBenchmarks).forEach(key => {
          const benchmarks = groupedBenchmarks[key];

          const averagedBenchmark = {
            ...benchmarks[0],
            statistics: benchmarks.reduce((accumulator, benchmark) => {
              benchmark.statistics.forEach(statistic => {
                let index = accumulator.findIndex(stat => stat.name === statistic.name);
                const averagedScore = statistic.score / benchmarks.length;

                if (index === -1) {
                  const length = accumulator.push({
                    name: statistic.name,
                    score: averagedScore,
                    details: [],
                  });

                  index = length - 1;
                } else {
                  accumulator[index].score += averagedScore;
                }

                if ('details' in statistic) {
                  const { details } = statistic;

                  details.forEach(detail => {
                    let detailIndex = accumulator[index].details.findIndex(det => det.name === detail.name);
                    const averagedDetailScore = detail.score / benchmarks.length;

                    if (detailIndex === -1) {
                      const detailsLength = accumulator[index].details.push({
                        name: detail.name,
                        score: averagedDetailScore,
                      });

                      detailIndex = detailsLength - 1;
                    } else {
                      accumulator[index].details[detailIndex].score += averagedDetailScore;
                    }
                  });
                }
              });

              return accumulator;
            }, []),
          };

          averagedBenchmarks.push(averagedBenchmark);
        });

        averagedBenchmarks = averagedBenchmarks.sort((a, b) => (new Date(a.built_at)).getTime() - (new Date(b.built_at)).getTime());
        const limitedBenchmarkLength = Math.min(15, averagedBenchmarks.length);

        for (let i = 0; i < limitedBenchmarkLength; i++) {
          const benchmark = averagedBenchmarks[averagedBenchmarks.length - limitedBenchmarkLength + i];

          names.push([benchmark.branch, (new Date(benchmark.built_at)).toLocaleDateString('en-US')]);

          const performance = benchmark.statistics.find(statistic => statistic.name === 'Performance');

          datasets.Performance.push(performance.score * 100);

          if ('details' in performance && performance.details.length !== 0) {
            performance.details.forEach(statistic => {
              datasets[statistic.name].push(statistic.score * 100);
            });
          } else {
            Object.keys(datasets).forEach(datasetKey => {
              if (datasetKey !== 'Performance') {
                datasets[datasetKey].push(null);
              }
            });
          }
        }

        this.setState({
          names,
          datasets,
        });
      });
  }

  render() {
    const { match } = this.props;
    const { theme } = match.params;
    const { names, datasets } = this.state;

    return (
      <Theme theme={theme}>
        <Tab
          animate
          name="performance"
        >
          <Performance performance={{ names, datasets }} />
        </Tab>
      </Theme>
    );
  }
}

ThemePerformance.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      theme: PropTypes.string,
    }),
  }).isRequired,
};
