import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

export default function Insights(props) {
  const { insights } = props;

  const chartColors = ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'];
  const datasets = Object.keys(insights.datasets).map((label, index) => ({
    label,
    data: insights.datasets[label],
    backgroundColor: chartColors[index % chartColors.length],
    borderColor: chartColors[index % chartColors.length],
    fill: false,
  }));

  return (
    <Line
      data={{
        labels: insights.names,
        datasets,
      }}
      options={{
        responsive: true,
        title: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      }}
      className="tab--chart"
    />
  );
}

Insights.propTypes = {
  insights: PropTypes.shape({
    names: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.object,
  }).isRequired,
};
